//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchInnochainSettleDetailList, fetchInnochainSettleProperty } from "@/api/finance";
import "@/utils/global.js";
import { downloadFun } from "@/utils/util";
import Pagination from "@/components/Pagination";
import { getToken } from "@/utils/cache";
export default {
  name: "InnochainSettleDetail",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        start_time: "",
        end_time: "",
        keyword: "",
        filter: {
          status: "",
          property_id: ''
        }
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      importLoading: false,
      total_settle_amount: 0,
      total_order_price: 0,
      total: 0,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      propertyList: []
    };
  },
  created: function created() {
    var _this = this;

    fetchInnochainSettleProperty().then(function (response) {
      _this.propertyList = response.data;
    });

    if (this.$route.query.property_id) {
      this.searchForm.filter.property_id = String(this.$route.query.property_id);
    }

    if (this.$route.query.finished_at) {
      this.searchForm['start_time'] = this.$route.query.finished_at + ' 00:00:00';
      this.searchForm['end_time'] = this.$route.query.finished_at + ' 23:59:59';
      this.searchForm.time = [this.searchForm['start_time'], this.searchForm['end_time']];
    }

    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this2 = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchInnochainSettleDetailList(data).then(function (response) {
        _this2.total_settle_amount = response.total_settle_amount;
        _this2.total_order_price = response.total_order_price;
        _this2.dataList = response.data;
        _this2.totalPage = response.meta.total;
        _this2.total = response.meta.total;
        _this2.tableLoading = false;
      }).catch(function (err) {
        _this2.tableLoading = false;

        _this2.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleTime: function handleTime(val) {
      if (val) {
        this.searchForm["start_time"] = val[0];
        this.searchForm["end_time"] = val[1];
      } else {
        this.searchForm["start_time"] = "";
        this.searchForm["end_time"] = "";
      }
    },
    resetForm: function resetForm(formName) {
      this.searchForm["start_time"] = "";
      this.searchForm["end_time"] = "";
      this.$refs[formName].resetFields();
    },
    handleDownload: function handleDownload() {
      console.log("导出");
      this.importLoading = true;
      var data = this.searchForm;
      var url = process.env.VUE_APP_URL + "/finance/innochain/settleDetail/down?token=".concat(getToken(), "&keyword=").concat(data.keyword || "", "\n        &start_time=").concat(data.start_time, "\n        &end_time=").concat(data.end_time, "\n        &filter[status]=").concat(data.filter.status || '', "\n        &filter[property_id]=").concat(data.filter.property_id || '');
      downloadFun(url, "创链账单明细.csv");
      this.importLoading = false;
    }
  }
};